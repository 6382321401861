import React, { useRef, useState, useEffect } from "react";
import Multiselect from "../../components/Multiselect";
import Close from "../../components/Close";
import Checkbox from "../../components/Checkbox";

import {DICTIONARY} from "../../lib/dictionaries";

const ProcedureNewStepTwo = (props) => {
  const { biodataOptions, setStep, defaultData, setEditData, editData, assessments } = props

  const [biodataOptionsLocal, setBiodataOptionsLocal] = useState([]);
  const [biodataRequired, setBiodataRequired] = useState([]);
  const [languageElementsSelected, setLanguageElementsSelected] = useState([]);
  const [assessmentsClonable, setAssessmentsClonable] = useState([]);
  const [options, setOptions] = useState(undefined);
  const [disabledButton, setDisabledButton] = useState(true);

  const refItemBiodataOptions = useRef([]);
  const refItemAssesments = useRef([]);
  const refItemLanguages = useRef([]);

  useEffect(() => {
    setTimeout(() => {
      refItemBiodataOptions.current.map((item) => {
        if (defaultData.biodataOptions.includes(item.id)) {
          item.click();
        }
      });

      refItemAssesments.current.map((item) => {
        if (getIdAsessment.includes(parseInt(item.id))) {
          item.click();
        }
      });
    }, 1000);
  }, [])

  useEffect(() => {
    console.log("New biodata options", biodataOptions)
  }, [biodataOptions])

  useEffect(() => {
    setEditData({
      ...editData,
      ["languages_selected"]: languageElementsSelected
    });
    console.log("Lang", languageElementsSelected)
  }, [languageElementsSelected])

  useEffect(() => {
    setEditData({
      ...editData,
      ["biodata_options"]: biodataOptionsLocal,
    });
    console.log("biodata options", languageElementsSelected)
  }, [biodataOptionsLocal])

  useEffect(() => {
    if(options) {
      let getAssessments = options.map((item) => {
        return {
          id: item,
          clonable: assessmentsClonable.includes(item) ? true : false,
        };
      });

      delete editData["biodataOptions"];

      setEditData({
        ...editData,
        ["assessments"]: getAssessments,
        ["biodata_options"]: biodataOptionsLocal,
      });
        console.log("biodata options", languageElementsSelected)
    }
  }, [options])

  useEffect(() => {
    setEditData({
      ...editData,
      ["biodata_required"]: biodataRequired,
    })
    console.log("biodata required", biodataRequired)
  }, [biodataRequired])


  useEffect(() => {
    buttonValidation()
    console.log("EDITDATA", editData)
  }, [editData])

  const removeBiodataOption = (id) => {
    let biodataFilter = biodataOptionsLocal.filter((item) => item !== id);
    refItemBiodataOptions.current.map((item) => {
      if (item.id === id + "") {
        item.click();
      }
    });
    setBiodataOptionsLocal(biodataFilter);
  };

  const removeAssessment = (id) => {
    let assessments = options.filter((item) => item !== id);
    let _assessmentsClonable = assessmentsClonable.filter(
      (item) => item !== id
    );

    refItemAssesments.current.map((item) => {
      if (item.id === id + "") {
        item.click();
      }
    });

    setOptions(assessments);
    setAssessmentsClonable(_assessmentsClonable);
  };
  
  const removeLanguage = (value) => {
    let languageFilter = languageElementsSelected.filter((item) => item !== value);
    refItemLanguages.current.map((item) => item.id === value + "" && item.click());
    setLanguageElementsSelected(languageFilter)
  };

  const biodataRequiredSelected = (id) => {
    let allIds = biodataRequired;

    if (allIds.length > 0) {
      if (allIds.find((item) => item === id) === undefined) {
        allIds = [...allIds, id];
      } else {
        allIds = allIds.filter((item) => item !== id);
      }
    } else {
      allIds = [...allIds, id];
    }
    setBiodataRequired(allIds);    
  }

  const getOptionsSelect = () => {
    return (
      assessments && assessments.map((item, index) => {
        return { value: item.id, text: item.text };
      })
    );
  };

  const optionSelect = (option) => {
    let newOption;
    if (assessments.length === 1) {
      newOption = [option];
    } else {
      newOption = option;
    }
    let newAssessmentsClonable = newOption.filter(i => assessmentsClonable.indexOf(i) !== -1);
    setAssessmentsClonable(newAssessmentsClonable)
    setOptions(newOption);
  };

  const buttonValidation = () => {
    if( editData.hasOwnProperty('assessments') && editData.assessments.length > 0 ) {
      if(editData.biodata_options.includes('language')) {
        if(editData.hasOwnProperty("languages_selected") && editData.languages_selected.length > 0) {
          setDisabledButton(false)
        }
        else {
          setDisabledButton(true)
        }
      } else {
        setDisabledButton(false)
      }
    }
  }

  return (
    <form style={{ maxWidth: 768 }}>
      <Multiselect
        refItem={refItemBiodataOptions}
        label={"Select biodata:"}
        multiselectClassName="c-multiselect--bottom-twenty-four"
        multiple={true}
        options={biodataOptions}
        getOption={(option) => setBiodataOptionsLocal(option)}
      />

      {biodataOptionsLocal.length > 0 && (
        <div className="c-tag__wrapper">
          {biodataOptions.map((item, index) => {
            console.log("ITEM", item)
            if (biodataOptionsLocal.includes(item.value)) {
              return (
                <div
                  className="c-tag"
                  onClick={() => removeBiodataOption(item.value)}
                >
                  <p className="c-tag-text">{item.text}</p>
                  <div className="c-tag-icon">
                    <Close width={"14px"} height={"13px"} color="#fc5c65" />
                  </div>
                </div>
              );
            }
          })}
        </div>
      )}

      {biodataOptions && biodataOptions.length > 0 && (
        <>
          <div className="c-box-checkbox">
            <p className="c-box-checkbox__label">
              From the selected biodata, choose which ones will be required:
            </p>
            <div className="c-box-checkbox__wrapper">
              {biodataOptionsLocal && biodataOptions.map((item, index) => {
                if (biodataOptionsLocal.length && biodataOptionsLocal.includes(item.value)) {
                  return (
                    <div className="c-box-checkbox__item">
                      <Checkbox
                        key={"check-email-title"}
                        text={item.text}
                        textClassName="c-checkbox__text"
                        onClick={() => biodataRequiredSelected(item.value)}
                        checked={
                          biodataRequired.includes(item.value)
                            ? true
                            : false
                        }
                      />
                      {biodataOptionsLocal.length > 1 && (
                        <div
                          className="c-box-checkbox__item-icon"
                          onClick={() => removeBiodataOption(item.value)}
                        >
                          <Close
                            color="#fc5c65"
                            width={"20px"}
                            height={"19px"}
                          />
                        </div>
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div className='c-modal-create__separator'></div>
        </>
      )}


      {biodataOptionsLocal.includes("language") && (
        <Multiselect
          refItem={refItemLanguages}
          label={"Select available languages:"}
          multiselectClassName="c-multiselect--bottom-twenty-four"
          multiple={true}
          options={DICTIONARY["profile"]["languages"].map((value) => { return { value: value[0], text: value[1] }})}
          getOption={(option) => setLanguageElementsSelected(option)}
        />
      )}

      {biodataOptionsLocal.includes("language") && languageElementsSelected.length > 0 && (
        <>
          <div className="c-tag__wrapper">
            {languageElementsSelected.map((item, index) => {
              return (
                <div
                  className="c-tag"
                  onClick={() => removeLanguage(item)}
                >
                  <p className="c-tag-text">{item}</p>
                  <div className="c-tag-icon">
                    <Close width={"14px"} height={"13px"} color="#fc5c65" />
                  </div>
                </div>
              );
            })}

          </div>
          <div className='c-modal-create__separator'></div>
        </>
      )}


      <Multiselect
        refItem={refItemAssesments}
        label={"Select assessment:"}
        multiselectClassName="c-multiselect--bottom-twenty-four"
        multiple={ assessments && assessments.length > 1 ? true : false }
        options={getOptionsSelect()}
        getOption={(option) => optionSelect(option)}
      />

      {options && options.length > 0 && (
        <div className="c-box-checkbox">
          <p className="c-box-checkbox__label">
            From the selected assessment:
          </p>
          <div className="c-box-checkbox__wrapper">
            {options && getOptionsSelect().map((item, index) => {
              if (options.length && options.includes(item.value)) {
                return (
                  <div className="c-box-checkbox__item">
                    <span>{item.text}</span>
                    {options.length > 0 && (
                      <div
                        className="c-box-checkbox__item-icon"
                        onClick={() => removeAssessment(item.value)}
                      >
                        <Close
                          color="#fc5c65"
                          width={"20px"}
                          height={"19px"}
                        />
                      </div>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}

      <div style={{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        width: 'fit-content',
        gap: 24,
        marginInline: 'auto',
      }}>
      <button
        type="button"
        className="c-button c-button--grey c-modal__button"
        style={{ marginInline: "auto", marginTop: 40 }}
        onClick={() => setStep(1)}
      >
        <span className="c-button__text">Atrás</span>
      </button>
      <button
        type="button"
        className="c-button c-modal__button"
        style={{ marginInline: "auto", marginTop: 40 }}
        disabled={disabledButton}
        onClick={() => setStep(3)}
      >
        <span className="c-button__text">Continuar</span>
      </button>
      </div>
    </form>
  );
};

export default ProcedureNewStepTwo;
