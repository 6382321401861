import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import BubbleBg from "../../components/BubbleBg";
import Spinner from "../../components/Spinner";

// Actions
import {
  fetchProcedureCreate,
  fetchOrganizationGetAssessments,
  fetchProceduresIndex,
} from "../admin/actions";


// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";
import ProcedureNewStepOne from "./ProcedureNewStepOne"
import ProcedureNewStepTwo from "./ProcedureNewStepTwo"
import ProcedureNewStepThree from "./ProcedureNewStepThree"

const ProcedureNew = (props) => {
  const [loading, setLoading] = useState(false);
  const [biodataOptions, setBiodataOptions] = useState([]);
  const [editData, setEditData] = useState({});
  const [step, setStep] = useState(1)

  useEffect(() => {
 		let bio = props.organization.data.biodata_options
    if(bio.filter((e) => e.value === "email").length == 0){
      bio.unshift({text: "Password", value: "password"})
      bio.unshift({text: "Email", value: "email"})  
      if(props.organization.data.id !== 125){
        bio = bio.filter((e) => e.value !== "uax_courses" && e.value !== "uax_current_course")
      }
      setBiodataOptions(bio)
    }
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      console.log("loaded")
    );
  },[])

  const submitCreate = (data) => {
    setLoading(true),
    data["title"] = data["name"];
    delete data["name"];
    props.fetchProcedureCreate(
      props.match.params.organizationId,
      data,
      () => {
        props.history.push(`/admin/organizations/${props.match.params.organizationId}/procedures`),
        setModals("success"),
          setModalMsg("The process has been created successfully!"),
          props.fetchProceduresIndex(props.match.params.organizationId, 1, 5000, () =>
            setLoading(false),
          );
      },
      () => "",
    );
  };

  const getAssessments = () => {
    props.history.push(`/admin/organizations/${props.match.params.organizationId}/procedure/new`);

    // let bio = props.organization.data.biodata_options
    // if(bio.filter((e) => e.value === "email").length == 0){
    //   bio.unshift({text: "Password", value: "password"})
    //   bio.unshift({text: "Email", value: "email"})
    //   if(props.organization.data.id !== 125){
    //     bio = bio.filter((e) => e.value !== "uax_courses" && e.value !== "uax_current_course")
    //   }
    //   setBiodataOptions(bio)
    // }

    // props.fetchOrganizationGetAssessments(
    //   props.match.params.organizationId,
    //   () => actionModals({}, "createProcedure")
    // );
  }

  const selectAction = (id) => {
    props.history.push(`/admin/organizations/${id}/procedures`);
  };

  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >

      {loading && <Spinner />}
      <BubbleBg />

      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations && props.organizations}
            organization={props.organization}
            selectAction={(id) => selectAction(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() => {
              setModals("closeSession"), setModalMsg("Are you sure you want to logout?");
            }}
          />
        )}

      <div className="o-container o-container--overflow-disabled">

        <h1 className="o-text-align o-text-align--left o-pos-rel" style={{ maxWidth: 768 }}>
          Procedure Create
          <span style={{
            fontSize: 18,
            right: 0,
            top: 0,
            position:'absolute', 
          }}>{step} / 3</span>
        </h1>

        {step == 1 && (
          <ProcedureNewStepOne 
            editData={editData} 
            setEditData={(data) => setEditData(data)}
            setStep={(step) => setStep(step)}
          />
        )}
        {step == 2 && (
          <ProcedureNewStepTwo
            setStep={(step) => setStep(step)}
            biodataOptions={biodataOptions}
            setBiodataOptions={(bio) => setBiodataOptions(bio)}
            defaultData={props.procedure}
            editData={editData}
            setEditData={(data) => setEditData(data)}
            assessments={props.organization.data.assessments}
          />
        )}
        {step == 3 && (
          <ProcedureNewStepThree
            setStep={(step) => setStep(step)}
            editData={editData}
            setEditData={(data) => setEditData(data)}
            sendData={(data) => submitCreate(editData)}
          />
        )}

      </div>

      {loading && <Spinner />}

    </motion.section>
  )
};

ProcedureNew.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
    procedure: state.admin.procedure,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
  };
};

const mapDispatchToProps = {
  fetchProcedureCreate,
  fetchOrganizationGetAssessments,
  fetchProceduresIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProcedureNew);
